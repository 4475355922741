import React from "react";
import Link from "next/link";
import NodeNewsListPreview from "components/News/NodeNewsListPreview";
import Button from "components/Button";
import useTranslation from "next-translate/useTranslation";

export default function HomepageNews({ nodes }) {
  const { t } = useTranslation("home");

  return (
    <section
      id="news"
      className="relative py-20 bg-secondary-light overflow-hidden"
    >
      <div
        className="absolute top-0 inset-x-0 h-2/5 bg-secondary-dark"
        aria-hidden="true"
      />
      <div className="relative container mx-auto px-4">
        <div
          className="absolute top-0 left-1/2 text-left text-white text-[210px] font-serif leading-none opacity-5"
          aria-hidden="true"
        >
          {t("news.decoration")}
        </div>
        <h2 className="mb-20 text-5xl text-white font-serif">
          {t("news.title")}
        </h2>

        <NodeNewsListPreview nodes={nodes.newses} />

        <div className="text-center mt-16">
          <Link href={t("common:news.path")} prefetch={false} passHref>
            <Button as="a" theme="primary">
              {t("news.more")}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}
