import React, { forwardRef } from "react";
import classNames from "lib/classNames";

const Button = forwardRef(
  (
    {
      children,
      theme = "primary",
      as = "button",
      size = "default",
      inverse = false,
      ...props
    },
    ref
  ) => {
    const Tag = as;

    const themes = {
      primary: {
        default:
          "border-primary bg-primary text-primary-darker hover:bg-primary-dark",
        inverse:
          "border-primary bg-transparent text-primary hover:bg-primary hover:text-primary-darker",
      },
      secondary: {
        default:
          "border-secondary bg-secondary text-white hover:bg-secondary-light",
        inverse:
          "border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white",
      },
    };

    const sizes = {
      default: "py-6 px-12",
      small: "py-4 px-8",
    };

    return (
      <Tag
        ref={ref}
        className={classNames(
          "inline-block rounded-full text-xs uppercase tracking-wider transition",
          inverse ? themes[theme].inverse : themes[theme].default,
          sizes[size]
        )}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

Button.displayName = "Button";
export default Button;
