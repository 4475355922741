import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "lib/classNames";

export default function NodeNewsTeaser({
  node,
  theme = "default",
  fill = false,
}) {
  const router = useRouter();

  const clickHandler = () => {
    router.push(node.path.alias);
  };

  return (
    <article
      className={classNames("group cursor-pointer relative", fill && "h-full")}
      onClick={() => clickHandler()}
    >
      {node.field_image?.image_style_uri.small_16by10 && (
        <div className="overflow-hidden aspect-w-16 aspect-h-10">
          <Image
            src={node.field_image.image_style_uri.small_16by10}
            {...node.field_image.resourceIdObjMeta}
            layout="fill"
            objectFit="cover"
            className="group-hover:scale-110 transition"
          />
        </div>
      )}

      <div
        className={classNames(
          "relative p-10",
          theme === "preview" ? "bg-white" : "bg-secondary-light",
          fill && "flex-1"
        )}
      >
        <h2 className="text-xl font-serif">
          <Link href={node.path.alias} prefetch={false} passHref>
            <a className="underline underline-offset-4 decoration-transparent transition-colors group-hover:decoration-primary">
              {node.title}
            </a>
          </Link>
        </h2>

        <span className="absolute top-0 left-0 -translate-y-1/2 px-3 py-1.5 bg-primary text-sm uppercase mb-4">
          {node.field_type.name}
        </span>
      </div>
    </article>
  );
}
