import React from "react";
import NodeNewsTeaser from "components/News/NodeNewsTeaser";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import Body from "components/Body";

export default function NodeNewsListPreview({ nodes }) {
  const router = useRouter();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
      {nodes.map((node, index) => {
        const isFirst = index === 0;

        return isFirst ? (
          <div
            key={node.id}
            className="sm:col-span-2 lg:col-span-3 xl:col-span-4"
          >
            <article
              className="group cursor-pointer relative flex flex-col sm:flex-row"
              onClick={() => router.push(node.path.alias)}
            >
              <div className="relative sm:absolute lg:relative top-0 right-0 w-full sm:w-2/5 xl:w-1/2">
                <div className="aspect-w-16 aspect-h-10 h-full overflow-hidden">
                  {node.field_image?.image_style_uri.medium_16by10 && (
                    <Image
                      src={node.field_image.image_style_uri.medium_16by10}
                      {...node.field_image.resourceIdObjMeta}
                      layout="fill"
                      objectFit="cover"
                      className="group-hover:scale-110 transition"
                    />
                  )}
                </div>
              </div>
              <div className="relative w-full lg:w-3/5 xl:w-1/2 p-10 lg:px-20 lg:py-16 bg-white">
                <div className="flex">
                  <div className="w-3/5 lg:w-full flex flex-col">
                    <h2 className="order-2 flex text-2xl font-serif mb-8">
                      <Link href={node.path.alias} prefetch={false} passHref>
                        <a className="underline underline-offset-4 decoration-transparent group-hover:decoration-primary">
                          {node.title}
                        </a>
                      </Link>
                    </h2>

                    <div className="order-1 mb-4">
                      <span className="inline-block px-3 py-1.5 bg-primary text-primary-darker text-sm uppercase">
                        {node.field_type.name}
                      </span>
                    </div>
                  </div>
                  <div
                    className="hidden sm:block lg:hidden w-2/5 h-full"
                    aria-hidden="true"
                  >
                    <div className="aspect-w-16 aspect-h-10" />
                  </div>
                </div>

                {node.body && (
                  <div className="order-3 prose max-w-none line-clamp-3">
                    <Body
                      value={
                        node.body.summary ? node.body.summary : node.body.value
                      }
                    />
                  </div>
                )}
              </div>
            </article>
          </div>
        ) : (
          <div key={node.id}>
            <NodeNewsTeaser node={node} theme="preview" />
          </div>
        );
      })}
    </div>
  );
}
